const initialState = {
  updatedSuccess: false,
  submitted: false,
  companyDetails: {
    companyId: '',
    manDaysTarget: '',
    gpmdTarget: '',
    overheadTarget: '',
    companyInfoId: '',
    rowVersion: '',
    rowVersion2: '',
    strRowVersion: '',
    strRowVersion2: '',
    addressId: '',
    contactId: '',
    rowVersionAddress: '',
    rowVersionContact: '',
    physicalAddressId: '',
    primaryContactId: '',
    strRowVersionAddress: '',
    strRowVersionContact: '',
    companyCode: 'new1',
    companyName: '',
    firstName: 'text1',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    phone1: '',
    phone1TypeId: '',
    phone2: '',
    phone2TypeId: '',
    emailAddress: '',
    webAddress: '',
    website: '',
    status: '',
    licenseState: '',
    contractorLicenseTypeId: '',
    contractorLicenseNo: '',
    grossIncomeLastYearAnnual: '',
    grossIncomeLastYearMonthly: '',
    grossIncomeLastYearWeekly: '',
    grossGrossProfitLastYearMonthly: '',
    grossGrossProfitLastYearWeekly: '',
    grossProfitLastYearAnnual: '',
    defaultMaterialMarkupPercent: '',
    defaultLaborMarkupPercent: '',
    commissionPercent: 0.07,
    totalCrewMembers: 0,
    averageHoursPerCrewMember: 45,
    averageHourlyWage: 18,
    averageLaborBurden: 0.23,
    overheadCostAmount: 0,
    desiredNetProfit: 20,
    closeOutWarningPct: '',
    strRowVersionAddress: '',
    strRowVersionContact: '',
  },
};

let nextState = null;

const companyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GETCOMPANYDETAILS':
      nextState = Object.assign({}, state);
      const {
        totalCrewMembers,
        averageHoursPerCrewMember,
        overheadCostAmount,
        desiredNetProfit,
      } = action.payload;
      const quickValues = [
        totalCrewMembers,
        averageHoursPerCrewMember,
        overheadCostAmount,
        desiredNetProfit,
      ];
      for (const object in nextState.companyDetails) {
        nextState.companyDetails[object] =
          action.payload[object] === null ? '' : action.payload[object];
      }
      nextState.isQuickValuesCompleted = !quickValues.includes(null);
      return nextState;
    case 'UPDATECOMPANYSAVE':
      nextState = Object.assign({}, state); // deep copying state
      nextState.updatedSuccess = action.payload.value;
      return nextState;
    default:
      return state;
  }
};

export default companyInfoReducer;
