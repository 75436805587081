const initialState = null;

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ERRORRESPONSE':
      return action.payload;
    default:
      return state;
  }
};

export default errorReducer;
