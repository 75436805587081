import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    primary: {
      main: '#3867ad',
    },
  },
  textField: {
    height: 45,
    fontWeight: 400,
  },
};

export const darkTheme = createMuiTheme({
  palette: {
    ...theme.palette,
  },
  textField: {
    ...theme.textField,
  },
  overrides: {
    root: {
      '& .MuiTextField-root': {
        padding: 0,
        height: '25px',
      },
    },
    MuiInput: {
      root: {
        maxHeight: '30px !important',
      },
    },
  },
});
