import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { MuiThemeProvider as NewMuiThemeProvider } from '@material-ui/core/styles';

import store from './redux/createStore';

// core components
import { darkTheme } from './config/theme/index';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/material-dashboard-react.css?v=1.8.0';
import { loginCheck } from 'redux/actions/authActions';

import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';

const history = createBrowserHistory();

if (Cookies.get('token')) {
  store.dispatch(loginCheck());
}

const PrivateRoute = ({ component: Component, ...Rest }) => (
  <Route
    {...Rest}
    render={props => (Cookies.get('token') ? <Component {...props} /> : <Redirect to="/login" />)}
  />
);

const Admin = lazy(() => import('layouts/Admin.js'));
const Login = lazy(() => import('views/Authentication/Login'));
const SignUp = lazy(() => import('views/Authentication/Signup'));
const ForgotPassword = lazy(() => import('views/Authentication/ForgotPassword'));

ReactDOM.render(
  <Provider store={store}>
    <NewMuiThemeProvider theme={darkTheme}>
      <Router history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute path="/admin" component={Admin} />
            <Redirect from="*" to="/admin/week-at-a-glance/in-house" />
          </Switch>
        </Suspense>
      </Router>
    </NewMuiThemeProvider>
    <ToastContainer autoClose={4000} position="top-right" />
  </Provider>,
  document.getElementById('root')
);
