export const TAX_ROWS = [
  {
    title: 'Federal Business Income Tax',
    weekly: 'taxSetAsideFedIncomeAmtWeekly',
  },
  {
    title: 'State Business Income Tax',
    weekly: 'taxSetAsideStateIncomeAmtWeekly',
  },
  {
    title: 'Property Tax',
    weekly: 'taxSetAsidePropertyAmtWeekly',
  },
  {
    title: 'Sales Tax',
    weekly: 'taxSetAsideSalesAmtWeekly',
  },
  {
    title: 'City Taxes',
    weekly: 'taxSetAsideCityAmtWeekly',
  },
  {
    title: 'Other',
    weekly: 'taxSetAsideOtherAmtWeekly',
  },
];

export const WEEKS = [
  { name: 'Sunday', day: 0 },
  { name: 'Monday', day: 1 },
  { name: 'Tuesday', day: 2 },
  { name: 'Wednesday', day: 3 },
  { name: 'Thursday', day: 4 },
  { name: 'Friday', day: 5 },
  { name: 'Saturday', day: 6 },
];

export const PHONE_TYPES_LIST = [
  { displaySequence: 1, phoneTypeDesc: 'Mobile', phoneTypeId: 4 },
  { phoneTypeId: 5, phoneTypeDesc: 'Work', displaySequence: 2 },
  { phoneTypeId: 6, phoneTypeDesc: 'Home', displaySequence: 3 },
  { phoneTypeId: 7, phoneTypeDesc: 'Fax', displaySequence: 4 },
];

export const STATES = [
  { abbreviation: 'AK', name: 'Alaska' },
  { abbreviation: 'AL', name: 'Alabama' },
  { abbreviation: 'AR', name: 'Arkansas' },
  { abbreviation: 'AZ', name: 'Arizona' },
  { abbreviation: 'CA', name: 'California' },
  { abbreviation: 'CO', name: 'Colorado' },
  { abbreviation: 'CT', name: 'Connecticut' },
  { abbreviation: 'DC', name: 'District of Columbia' },
  { abbreviation: 'DE', name: 'Delaware' },
  { abbreviation: 'FL', name: 'Florida' },
  { abbreviation: 'GA', name: 'Georgia' },
  { abbreviation: 'HI', name: 'Hawaii' },
  { abbreviation: 'IA', name: 'Iowa' },
  { abbreviation: 'ID', name: 'Idaho' },
  { abbreviation: 'IL', name: 'Illinois' },
  { abbreviation: 'IN', name: 'Indiana' },
  { abbreviation: 'KS', name: 'Kansas' },
  { abbreviation: 'KY', name: 'Kentucky' },
  { abbreviation: 'LA', name: 'Louisiana' },
  { abbreviation: 'MA', name: 'Massachusetts' },
  { abbreviation: 'MD', name: 'Maryland' },
  { abbreviation: 'ME', name: 'Maine' },
  { abbreviation: 'MI', name: 'Michigan' },
  { abbreviation: 'MN', name: 'Minnesota' },
  { abbreviation: 'MO', name: 'Missouri' },
  { abbreviation: 'MS', name: 'Mississippi' },
  { abbreviation: 'MT', name: 'Montana' },
  { abbreviation: 'NC', name: 'North Carolina' },
  { abbreviation: 'ND', name: 'North Dakota' },
  { abbreviation: 'NE', name: 'Nebraska' },
  { abbreviation: 'NH', name: 'New Hampshire' },
  { abbreviation: 'NJ', name: 'New Jersey' },
  { abbreviation: 'NM', name: 'New Mexico' },
  { abbreviation: 'NV', name: 'Nevada' },
  { abbreviation: 'NY', name: 'New York' },
  { abbreviation: 'OH', name: 'Ohio' },
  { abbreviation: 'OK', name: 'Oklahoma' },
  { abbreviation: 'OR', name: 'Oregon' },
  { abbreviation: 'PA', name: 'Pennsylvania' },
  { abbreviation: 'PR', name: 'Puerto Rico' },
  { abbreviation: 'RI', name: 'Rhode Island' },
  { abbreviation: 'SC', name: 'South Carolina' },
  { abbreviation: 'SD', name: 'South Dakota' },
  { abbreviation: 'TN', name: 'Tennessee' },
  { abbreviation: 'TX', name: 'Texas' },
  { abbreviation: 'UT', name: 'Utah' },
  { abbreviation: 'VA', name: 'Virginia' },
  { abbreviation: 'VT', name: 'Vermont' },
  { abbreviation: 'WA', name: 'Washington' },
  { abbreviation: 'WI', name: 'Wisconsin' },
  { abbreviation: 'WV', name: 'West Virginia' },
  { abbreviation: 'WY', name: 'Wyoming' },
];

export const CUSTOMER_TYPES = [
  { name: 'Residential', id: 1 },
  // { name: 'Family', id: 2 },
  { name: 'Commercial', id: 3 },
];

export const INCOME_DIVISION_TYPE = [
  { typeDesc: 'Crew', typeValue: false },
  { typeDesc: 'Non-Crew', typeValue: true },
];

export const INSURANCE_ROWS = [
  {
    title: 'Vehicle Insurance',
    monthly: 'vehicleInsuranceCostMonthly',
    weekly: 'vehicleInsuranceCostWeekly',
  },
  {
    title: 'General Liability Insurance',
    monthly: 'generalLiabilityInsuranceCostMonthly',
    weekly: 'generalLiabilityInsuranceCostWeekly',
  },
  {
    title: 'Property Insurance',
    monthly: 'propertyInsuranceCostMonthly',
    weekly: 'propertyInsuranceCostWeekly',
  },
  {
    title: 'Life Insurance',
    monthly: 'lifeInsuranceCostMonthly',
    weekly: 'lifeInsuranceCostWeekly',
  },
  {
    title: 'Health Insurance',
    monthly: 'healthInsuranceCostMonthly',
    weekly: 'healthInsuranceCostWeekly',
  },
  {
    title: 'Other Insurance',
    monthly: 'otherInsuranceCostMonthly',
    weekly: 'otherInsuranceCostWeekly',
  },
];
