import meanBy from 'lodash/meanBy';

const initialState = {
  historicalsummery: {
    totalGrossIncome: 0,
    netIncome: {
      totalInhouseGp: 0,
      totalInhouseGpWeekly: 0,
      totalSubGp: 0,
      totalSubGpWeekly: 0,
      totalGrossProfit: 0,
      totalGrossProfitWeekly: 0,
      totalManDays: 0,
      totalManDaysWeekly: 0,
      gpmd: 0,
      gpmdWeekly: 0,
      totalOverhead: 0,
      totalOverheadWeekly: 0,
      netProfit: 0,
      netProfitWeekly: 0,
    },
  },
  targetedsummery: {
    totalGrossIncome: 0,
    summery: {},
    netIncome: {
      totalInhouseGp: 0,
      totalInhouseGpWeekly: 0,
      totalSubGp: 0,
      totalSubGpWeekly: 0,
      totalGrossProfit: 0,
      totalGrossProfitWeekly: 0,
      totalManDays: 0,
      totalManDaysWeekly: 0,
      gpmd: 0,
      gpmdWeekly: 0,
      totalOverhead: 0,
      totalOverheadWeekly: 0,
      netProfit: 0,
      netProfitWeekly: 0,
    },
  },
  companyCogsInfoSummary: {
    avgCommissionPercent: 0,
  },
  incomeSourceInHouseCogsLists: [],

  allConstantsLists: []
};

let nextState = null;

const plSummeryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCOMPANYINCOMESOURCECOGSPL':
      nextState = JSON.parse(JSON.stringify(state));

      if (action.payload.length > 0) {
        nextState.incomeSourceInHouseCogsLists = action.payload;
        const avgCommissionPercent = meanBy(action.payload.map(e => e.commissionPercent));
        nextState.companyCogsInfoSummary.avgCommissionPercent = avgCommissionPercent;
      }

      return nextState;

    case 'SETGETCONSTANTS':
      nextState = JSON.parse(JSON.stringify(state));
      if (action.payload.length > 0) {
        nextState.allConstantsLists = action.payload;
      }

      return nextState;
    default:
      return state;
  }
};
export default plSummeryReducer;
